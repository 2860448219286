<template>
  <v-row no-gutters class="mb-3">
    <fieldName :fieldAttributes="fieldAttributes"></fieldName>
    <fieldLabel :fieldAttributes="fieldAttributes" :field="field"> </fieldLabel>
  </v-row>
</template>
<script>
import fieldName from "@/commonComponents/fieldName.vue";
import fieldLabel from "@/commonComponents/fieldLabel.vue";
export default {
  props: ["result","fieldAttributes","field"],
  components: { fieldLabel, fieldName },
};
</script>